import * as React from "react"
import '../style/style.css';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Text from '../components/Text';
import { StaticImage, GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { theme } from '../style/theme';
import { device } from "../components/Devices";
import { graphql, useStaticQuery } from "gatsby";


const HeaderSection = styled.section`
    position: relative;

    .filosofia-img-header{
        height: 100vh;
    }

    .filosofia-img-container{
        height: 100vh;
        object-position: center 90%;
    }

    @media (max-height: 660px){
        .filosofia-img-container{
            object-position: center 60% !important;
        }
    }

    @media ${device.mobileL}{
        .filosofia-img-container{
            object-position: center 30%;
        }

        .filosofia-banner-brush{
            bottom: -1% !important;
        }

        .filosofia-img-brush{
            object-fit: fill !important;
        }
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-left: ${theme.paddings.left};
    top: 0;
    height: 100vh;
    margin-top: -1%;
    justify-content: center;
    width: 50%;

    @media (max-height: 660px){
        margin-top: -4%;
    }

    @media ${device.laptopM}{
        padding-left: ${theme.paddings.leftLaptopM};
    }

    @media ${device.laptop}{
        padding-left: ${theme.paddings.leftLaptop};
    }

    @media ${device.tablet} {
        top: 1%;
        left: 0;
        padding: ${theme.paddings.mainTablet};
        padding-top: 100px;
        justify-content: start;
        width: 100%;

        .FilosofiaTitle, .FilosofiaHeadDescription{
            text-align: center;
            width: 100%;
        }
    }

    @media ${device.mobileL}{
        /* margin-top: 20%; */

        .FilosofiaHeadDescription{
            font-size: 0.9rem;
            margin-top: 5%;
        }
    }
`;

const Container = styled.div`    
    display: flex;
    flex-direction: row;
    padding: ${theme.paddings.main};
    margin-bottom: 3%;
    width: 100%;

    .filosofiaPorcentajeFooter{
        display: none;
    }

    @media ${device.laptopM}{
        padding: ${theme.paddings.mainLaptopM}
    }

    @media ${device.laptop}{
        padding: ${theme.paddings.mainLaptop}
    }

    @media ${device.tablet}{
        flex-direction: column;
        padding: ${theme.paddings.mainTablet};
        align-items: center;

        .filosofiaPorcentajeFooter{
            display: block;
            width: 50%;
            z-index: -1;
        }
    }

    @media ${device.mobileL}{
        .filosofiaPorcentajeFooter{
            width: 70%;
        }
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    width: 30%;

    .filosofiaPorcentaje{
        margin: 10% 0;
        width: 100%;
    }

    .TitleIngredientes{
        font-size: 3.1rem;
    }

    @media ${device.tablet}{
        align-items: center;
        width: 100%;

        .TitleIngredientes, .TextIngredientes {
            text-align: center;
            width: 100%;
        }

        .TitleIngredientes{
            font-size: ${theme.fonts.size.m.title};
        }

        .filosofiaPorcentaje {
            display: none;
        }
    }

    @media ${device.mobileL}{
        .TitleIngredientes{
            font-size: ${theme.fonts.size.s.title};
        }
    }
`;

const TextStyled = styled(Text)`
    margin-top: 2%;
    line-height: 1.3;
    white-space: pre-line;
    width: 104%;
`;

const IngredientesWrapper = styled.div`
    align-items: center;
    display: flex;
    position: relative;
    justify-content: center;
    width: 70%;

    .POLLO {
        top: 6%;
        width: 25%;
        transform: translatex(-10%) translateY(-10%);
        z-index: 2;
    }
    
    .POLLO .ContentIngredient {
        left: -70%;
        width: 250%;
        top: 10%;
    }

    .ARVEJA .ContentIngredient {
        width: 130%;
    }

    .HUEVO .ContentIngredient {
        left: -70%;
        width: 250%;
        bottom: -24%;
    }

    .ARROZ .ContentIngredient {
        width: 80%;
    }

    .BATATA .ContentIngredient {
        left: -10%;
        width: 110%;
        z-index: 0;
    }

    .POLLO:hover .ContentIngredient,
    .CORDERO:hover .ContentIngredient,
    .ARVEJA:hover .ContentIngredient,
    .HUEVO:hover .ContentIngredient,
    .ARROZ:hover .ContentIngredient,
    .BATATA:hover .ContentIngredient
    {
        display: block;
        z-index: 10;
    }

    .CORDERO {
        top: 17%;
        right: 10%;
        width: 25%;
        z-index: 1;
    }

    .ARVEJA {
        right: 14%;
        top: 57%;
        width: 20%;
        z-index: 0;
    }

    .HUEVO {
        bottom: 8%;
        width: 20%;
        z-index: 1;
    }

    .ARROZ {
        left: 6%;
        top: 50%;
        width: 35%;
        z-index: 0;
    }

    .BATATA {
        left: 10%;
        top: 20%;
        width: 25%;
        z-index: 0;
    }

    @media ${device.tablet}{
        width: 100%;

        .imgAlimento{
            margin: 30% 0;
            width: 50%;
        }

        .CORDERO {
            right: 5%;
        }

        .ARVEJA {
            right: 7%;
        }

        .HUEVO {
            bottom: 8%;
            width: 20%;
        }

        .ARROZ {
            left: 3%;
        }

        .BATATA {
            left: 5%;
        }

        .POLLO .ContentIngredient {
            left: -65%;
            top: 20%;
        }

        .CORDERO .ContentIngredient {
            top: -15%;
            width: 130%;
        }

        .ARVEJA .ContentIngredient {
            right: -47%;
            width: 162%;
        }

        .HUEVO .ContentIngredient {
            left: -88%;
            top: -6%;
            width: 280%;
            height: fit-content;
        }

        .ARROZ .ContentIngredient {
            left: -8%;
            top: 23%;
            width: 110%;
        }

        .BATATA .ContentIngredient {
            left: -21%;
            width: 156%;
            top: 18%;
        }
    }

    @media ${device.mobileL}{
        .POLLO .ContentIngredient {
            top: 0%;
        }

        .CORDERO .ContentIngredient {
        }

        .ARVEJA .ContentIngredient {
        }

        .HUEVO .ContentIngredient {
            top: -65%;
        }

        .ARROZ .ContentIngredient {
            top: -7%;
        }

        .BATATA .ContentIngredient {
            top: -22%;
        }
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
`;

const ContentWrapper = styled.div`
    background-color: rgba(255,255,255, 0.7);
    border: 1px solid ${theme.colors.background.main};
    border-radius: 20px;
    display: none;
    padding: 6%;
    position: absolute;
    z-index: 999;

    h2 {
        color: ${theme.colors.text.h2};
        font-family: ${theme.fonts.type.h2};
        font-size: ${theme.fonts.size.l.h2};
        text-align: center;
        margin: 1% 0;
    }

    span{
        color: ${theme.colors.text.main};
        display: block;
        font-family: ${theme.fonts.type.text};
        font-size: ${theme.fonts.size.l.textSlider};
        line-height: 1.2;
        text-align: center;
    }

    @media ${device.tablet} {
        h2 {
            font-size: 1rem;
        }

        span {
            font-size: 0.8rem;
        }
    }
`;


const FilosofiaPage = () => {

    const data = useStaticQuery(graphql`
    query{
        
        headerImage: file(relativePath: {eq: "Home/MainBanner/BANNER1-1.jpg"}) {
            childImageSharp {
              gatsbyImageData
              id
            }
        }

        headerImageLaptop:file(relativePath: {eq: "Filosofia/BANNER-LAPTOP.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 100
              )
              id
            }
        }

        headerImageMobile:file(relativePath: {eq: "Home/MainBannerMobile/BANNER1-1.jpg"}) {
            childImageSharp {
              gatsbyImageData(
                quality: 100
              )
              id
            }
        }

        brushLine: file(relativePath: {eq: "Home/BrushLine.png"}) {
            childImageSharp {
              gatsbyImageData
              id
            }
          }

          brushLineMobile: file(relativePath: {eq: "Home/BrushLineMobile.png"}) {
            childImageSharp {
              gatsbyImageData
              id
            }
          }

        site {
            siteMetadata {
              filosofiaSite {
                informacion
                ingrediente
              }
            }
          }
          ingredientes: allFile(
            filter: {relativeDirectory: {eq: "Filosofia/Ingredientes"}}
            sort: {fields: base, order: ASC}
          ) {
            nodes {
              childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                )
                id
              }
            }
          }
    }
    `);

    const imageHeader = withArtDirection(getImage(data.headerImage.childImageSharp.gatsbyImageData), [
        {
            media: '(max-width: 420px)',
            image: getImage(data.headerImageMobile.childImageSharp.gatsbyImageData)
        },
        {
            media: "(max-width: 1290px)",
            image: getImage(data.headerImageLaptop.childImageSharp.gatsbyImageData)
        },
    ]);

    const infoIngredientes = data.site.siteMetadata.filosofiaSite;
    const imgIngredientes = data.ingredientes.nodes;

    return (
    <main>
    <Layout>
        <HeaderSection>
            <GatsbyImage 
            alt='Alimentación consiente' 
            image={imageHeader} 
            className={'filosofia-img-header'}
            imgClassName={'filosofia-img-container'}
            />
            <GatsbyImage image={withArtDirection(getImage(data.brushLine.childImageSharp.gatsbyImageData),[
            {
            media: "(max-width: 420px)",
            image: getImage(data.brushLineMobile.childImageSharp.gatsbyImageData)
            },
            ])}
            alt='Line' 
            className='filosofia-banner-brush'
            imgClassName="filosofia-img-brush"
            style={{ display: 'flex', position: 'absolute', width: '100%', zIndex: '9999', bottom: '-2%'}}
            />
            <HeaderWrapper>
                <Title className={'FilosofiaTitle'} title='Alimentación Consciente' color={theme.colors.text.main}/>
                <Text className={'FilosofiaHeadDescription'} 
                    text={`La filosofía de Livra se centra en ser conscientes de cómo nutrimos a nuestros compañeros incondicionales, desde los ingredientes hasta el proceso que hace llegar el alimento a su plato. La comida de calidad, la producción, su disfrute, el cuidado del ecosistema y tu economía: es la combinación de todo esto con el amor y la conexión entre las personas y nuestras mascotas.`}
                    />
            </HeaderWrapper>
            </HeaderSection>
            <Container>
                <TextWrapper>
                    <Title className={'TitleIngredientes'} title='Ingredientes de calidad' color={theme.colors.text.main}/>
                    <TextStyled className={'TextIngredientes'} 
                        text={`La salud de nuestras mascotas depende de las elecciones que tomemos y definir su alimentación es una decisión esencial. Por eso, inspirados en nuestras propias mascotas, desarrollamos un alimento elaborado con ingredientes reales que conocemos y consumimos a diario y los pusimos en el primer lugar de la lista, no sólo para cubrir las necesidades nutricionales de nuestras mascotas, sino para que disfruten su comida al igual que nosotros disfrutamos la nuestra, justamente porque creemos que lo que es bueno para nosotros, también lo es para ellos.`}
                        />
                    <StaticImage src='../images/Filosofia/porcentajes.png' alt='Contenidos' className={'filosofiaPorcentaje'} placeholder="blurred"/>
                </TextWrapper>
                <IngredientesWrapper>
                    <StaticImage src='../images/Filosofia/Ingredientes/0-alimento.png' alt='Alimento LIVRA' className="imgAlimento" placeholder="blurred"/>
                    {
                        infoIngredientes.map((info,index) => (
                            <ImageWrapper className={info.ingrediente}>
                                <GatsbyImage loading="eager" image={imgIngredientes[index + 1].childImageSharp.gatsbyImageData}  alt={'Alimento-'+imgIngredientes[index]}/>
                                <ContentWrapper className={'ContentIngredient'}>
                                    <h2>{info.ingrediente}</h2>
                                    <span>{info.informacion}</span>
                                </ContentWrapper>
                            </ImageWrapper>
                        ))
                    }
                </IngredientesWrapper>
                <StaticImage src='../images/Filosofia/porcentajes.png' alt='Contenidos' className={'filosofiaPorcentajeFooter'} placeholder="blurred"/>
            </Container>
    </Layout>
  </main>
  );
}

export default FilosofiaPage

export { Head } from "../components/Head";